.TitleScreen {
    height: 100vh;
    height: 100svh;
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow: hidden; */
}

.MainTitle {
    display: block;
    position: relative;
    overflow: hidden;
    --titleWidth: min(30rem, 90vw);
    --titleHeight: calc(var(--titleWidth) / 3);
    width: var(--titleWidth);
    height: var(--titleHeight);
    animation: slide-in-blurred-top 1.6s ease-in-out forwards;
}
.MainTitle canvas {
    position: absolute;
    top:0;
    left:0;
    /* border: solid 1rem; */
    border-image: url('../AssetsImport/borders/bolt.png');
    border-image-repeat: round;
    border-image-slice: 8;
    border-radius: 2rem;
}
.SnakeLoadbar {
    border: none;
}
.MainTitle h1::first-letter {
    color: limegreen;
}
.MainTitle h1 {
    /* margin-left: 50%;
    margin-top: 50%; */
    padding: 0;
    margin: 0;
    position: absolute;
    left: 50%;
    top:50%;
    width: min-content;
    font-size: min(5rem, 15vw);
    color:forestgreen;
    transform: translate(-50%,-50%);
    z-index: 20;
}


.SubTitle {
    position:relative;
    padding-inline: 2rem;
    margin-bottom: 4rem;
    animation: slide-in-fwd-center 1.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.6s both;
}
.SubTitle::after,
.SubTitle::before {
    content: '';
    position: absolute;
    top:50%;
    left: 100%;
    transform: translateY(-50%);
    height: 3rem;
    width: 3rem;
    background-image: url('../Components/NoteView/svg/8nn.svg');
    background-size:auto;
}
.SubTitle::before {
    left: unset;
    right: 100%;
    transform: translateX(50%) translateY(-50%);
    background-image: url('../Components/NoteView/svg/8n.svg');
}


.Selections {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: slide-in-fwd-center 1.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.6s both;
}
.Selections > div {  
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.Selections .submenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.Selections .submenu div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.Selections .submenu .title {
  margin-block: 0.5rem;
  display: inline-block;
  margin-inline: auto;
  width: 100%;
  text-align: center;
}

.Selections .submenu .Volumes {
  display: flex;
  flex-direction: column;
}
.Selections .submenu .Frame {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}


.WhatsNew{
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
	  animation: slide-in-bottom 2.5s ease-out 2s both;
}
.WhatsNew div{
    display: flex;
    align-items: center;
}
.WhatsNew img{
    width: 2rem;
    height: 2rem;
}
.WhatsNew ul{
  list-style-position: inside;
}
.WhatsNew ul li.new {
  color:forestgreen;
}
.WhatsNew ul li.del {
  color:orangered;
}

.VersionTag{
    position: absolute;
    bottom: 0;
    right: 0;
}


/* ----------------------------------------------
 * Generated by Animista on 2023-4-7 21:40:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-top
 * ----------------------------------------
 */
 @keyframes slide-in-blurred-top {
    0% {
      transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
      transform-origin: 50% 0%;
      filter: blur(40px);
      opacity: 0;
    }
    100% {
      transform: translateY(0) scaleY(1) scaleX(1);
      transform-origin: 50% 50%;
      filter: blur(0) drop-shadow(2px 2px 8px black);
      opacity: 1;
    }
  }
  

  /* ----------------------------------------------
 * Generated by Animista on 2023-4-7 21:31:54
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-fwd-center
 * ----------------------------------------
 */

  @keyframes slide-in-fwd-center {
    0% {
      -webkit-transform: translateZ(-1400px);
              transform: translateZ(-1400px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }
  }
/* ----------------------------------------------
 * Generated by Animista on 2023-4-7 22:27:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@keyframes slide-in-bottom {
    0% {
      transform: translateY(200%);
    }
    100% {
      transform: translateY(0);
    }
  }
  