.Bubble {
    padding: 0;
    border: solid  20px rgba(0,0,0,0.5);
    border-image: url('../AssetsImport/borders/bubbleBorder.png');
    border-image-slice: 7 fill;
    image-rendering: pixelated;
    position: relative;
    box-sizing: border-box;
    filter: drop-shadow(2px 2px 10px black);
  }
  .Bubble * {
    padding: 0;
    margin: 0;
  }
  .Bubble.Stem::after {
    position: absolute;
    content: '';
    width: 16px;
    height: 18px;
    top: calc(100% + 16px);
    left: calc(50% - 16px); 
    background-image: url('../AssetsImport/borders/bubleStemBot.png');
    background-size: cover;
  }
  .Bubble.Stem.Left::after {
    transform: rotate(90deg);
    top: calc(50% - 8px); 
    right: calc(100% + 16px); 
    left: unset;
    bottom: unset;
  }
  .Bubble.Stem.Right::after {
    transform: rotate(-90deg);
    top: calc(50% - 8px); 
    left: calc(100% + 16px); 
    right: unset;
    bottom: unset;
  }
  .Bubble.Stem.Up::after {
    transform: rotate(180deg);
    top: unset;
    bottom: calc(100% + 16px);
    left: calc(50% - 16px); 
    right: unset;
  }
  