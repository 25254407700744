.GameBasePitch {
  background-color: black;
  overflow: hidden;
}
.GameBasePitch::after{
  content: unset;
}
.GameBasePitch.Fade::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
}

.GameOverlayMenu {
  position: absolute;
  left: 50%;
  top:  50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.GameOverlayMenu.Pause{
  background-color: aliceblue;
  border-image-outset: 16px;
}

.GameBasePitch  .NoteView {
  position: absolute;
  width: 12vh;
  height: 12vh;
  border: solid forestgreen 0.25rem;
  border-radius: 1rem;
  background: ivory;
  transform: translate(-50%,-50%);
  opacity: 70%;
}
.GameBasePitch  .SnakeView {
  position: relative;
}
.GameBasePitch .GuessA.GuessHorizontal {
  top: 6vh;
  left: 50%;
  right: unset;
  bottom: unset;
}
.GameBasePitch .GuessB.GuessHorizontal {
  top: unset;
  left: 50%;
  right: unset;
  bottom: -6vh;
}
.GameBasePitch  .GuessA {
  top: 50%;
  left: 6vh;
  right: unset;
  bottom: unset;
}
.GameBasePitch  .GuessB {
  top: 50%;
  left: unset;
  right: -6vh;
  bottom: unset;
}

.GameBasePitch  .Frame.Snake {
  height: 65vh;
  margin-bottom: 0;
  position: relative; 
}

.GameBasePitch  .InputMethod {
  height: 25vh;
}
.GameBasePitch  .Status {
  height: 10vh;
  padding: 0;
  background: black;
  color: pink;
  display:flex;
  align-items:center;
  justify-content: space-between;
  padding-inline: 1rem;
}

.GameBasePitch  .Status span{
  font-size: min(6vh,6vw);
  text-align: center;
  position: relative;
  filter: drop-shadow(0px 0px 5px black);
}
.GameBasePitch  .Status .Score.streak::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5;
  background-image: url('../AssetsImport/icons/fire.png');
  background-size: contain;
  background-repeat:space;
  background-position: 50%;
  image-rendering: pixelated;
}
.GameBasePitch  .Status .Score.streak::after{
  content: ' ';
}
.GameBasePitch  .Status .Score.streak.low::after{
  content: ' x2';
}
.GameBasePitch  .Status .Score.streak.mid::after{
  content: ' x3';
  color:springgreen;
}
.GameBasePitch  .Status .Score.streak.high::after{
  content: ' x4';
  color:aqua;
}
.GameBasePitch .statusArrow{
  content: url('../AssetsImport/icons/arrow.png');
  height:5vh;
}
.GameBasePitch .statusArrow.pending{
  filter: hue-rotate(-90deg);
}
.GameBasePitch .HealthBar {
  image-rendering: pixelated;
  height: 10vh;
  display: flex;
  gap: 0.9rem;
  align-items: center;
}
.GameBasePitch .statusHeart{
  content: url('../AssetsImport/icons/heart.png');
  width: min(5vh,8vw);
}
.GameBasePitch .statusHeart.empty{
  content: url('../AssetsImport/icons/heart_empty.png');
  width: min(5vh,8vw);
}

.GameBasePitch .Countdown {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}
.GameBasePitch .Countdown h1 {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 5rem;
  filter: drop-shadow(1px 1px 5px black);
}

.HowToPlayInfo{
  position: absolute;
  z-index: 14;
  width: 80%;
  left:10%;
  right:10%;
  top:10%;
  bottom:10%;
}
.HowToPlayInfo button{
  margin-top: 0.5rem;
}
.HowToPlayInfo .Bubble {
  padding: 1rem;
}
.HowToPlayInfo .NoteView {
  margin-left: auto;
  border: solid 1px black;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
}

.btnHelpHowTo.Attention {
  animation: heartbeat 1.5s ease-in-out infinite both;
  filter: sepia(0.5) hue-rotate(90deg);
}
/* ----------------------------------------------
 * Generated by Animista on 2023-5-13 11:16:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
 @keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}
